export const getMatched = (lookingIds = [], fullElements) => {
	const matched = fullElements.filter((objectToMatch) =>
		lookingIds.includes(objectToMatch._id),
	);

	return matched;
};

export const makeVisible = (elementsToShow) =>
	elementsToShow.map((el) => el.name).join(', ');

export const convertToOptions = (fullElements) =>
	fullElements.map((object) => ({ value: object._id, label: object.name }));
