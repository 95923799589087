import { ROLES_ENUM } from '../../../constants/roles';

export const NAVIGATION_CONFIG = (pathname, handleNavigation) => [
	{
		access: [ROLES_ENUM.ADMIN],
		value: (
			<li
				className='animation-scale'
				onClick={() => handleNavigation('/reports')}
				style={{
					transform:
						pathname.split('/')[1] === 'reports' ? 'scale(1.1)' : 'scale(1)',
					textDecoration:
						pathname.split('/')[1] === 'reports' ? 'underline' : null,
				}}
			>
				Reports
			</li>
		),
	},
	{
		access: [ROLES_ENUM.ADMIN],
		value: (
			<li
				style={{
					transform:
						pathname.split('/')[1] === 'jobs' ? 'scale(1.1)' : 'scale(1)',
					textDecoration:
						pathname.split('/')[1] === 'jobs' ? 'underline' : null,
				}}
				className='animation-scale'
				onClick={() => handleNavigation('/jobs')}
			>
				Jobs
			</li>
		),
	},
	{
		access: [ROLES_ENUM.ADMIN],
		value: (
			<li
				className='animation-scale'
				style={{
					transform:
						pathname.split('/')[1] === 'users' ? 'scale(1.1)' : 'scale(1)',
					textDecoration:
						pathname.split('/')[1] === 'users' ? 'underline' : null,
				}}
				onClick={() => handleNavigation('/users')}
			>
				Users
			</li>
		),
	},
	{
		access: [ROLES_ENUM.ADMIN, ROLES_ENUM.USER],
		value: (
			<li
				style={{
					transform:
						pathname.split('/')[1] === 'orders' ? 'scale(1.1)' : 'scale(1)',
					textDecoration:
						pathname.split('/')[1] === 'orders' ? 'underline' : null,
				}}
				className='animation-scale'
				onClick={() => handleNavigation('/orders')}
			>
				Orders
			</li>
		),
	},
	{
		access: [ROLES_ENUM.ADMIN, ROLES_ENUM.USER],
		value: (
			<li
				style={{
					transform:
						pathname.split('/')[1] === 'books' ? 'scale(1.1)' : 'scale(1)',
					textDecoration:
						pathname.split('/')[1] === 'books' ? 'underline' : null,
				}}
				className='animation-scale'
				onClick={() => handleNavigation('/books')}
			>
				Books
			</li>
		),
	},
	{
		access: [ROLES_ENUM.ADMIN, ROLES_ENUM.USER, ROLES_ENUM.LABEL_SENDER],
		value: (
			<li
				style={{
					transform:
						pathname.split('/')[1] === 'parcels' ? 'scale(1.1)' : 'scale(1)',
					textDecoration:
						pathname.split('/')[1] === 'parcels' ? 'underline' : null,
				}}
				className='animation-scale'
				onClick={() => handleNavigation('/parcels')}
			>
				Parcels
			</li>
		),
	},
	{
		access: [ROLES_ENUM.ADMIN],
		value: (
			<li
				className='animation-scale'
				style={{
					transform:
						pathname.split('/')[1] === 'settings' ? 'scale(1.1)' : 'scale(1)',
					textDecoration:
						pathname.split('/')[1] === 'settings' ? 'underline' : null,
				}}
				onClick={() => handleNavigation('/settings')}
			>
				Settings
			</li>
		),
	},
];
