import React from 'react';
import Select from 'react-select';

const customStyles = (width) => {
	return {
		container: (provided) => ({
			...provided,
			width: `${width}px`,
		}),
		control: (provided, state) => ({
			...provided,
			borderRadius: '10px',
			width: `${width}px`,
		}),
		menu: (provided) => ({
			...provided,
			width: `${width}px`,
		}),
	};
};

const SelectInput = ({
	name,
	options,
	setSelected,
	selected,
	multiple = false,
	width = 80,
	selectWidth = 140,
	setAccesses,
	labelUp = false,
	color = 'black',
}) => {
	const handleChange = (selected) => {
		setSelected(selected);
	};

	return (
		<div
			style={{
				display: labelUp ? 'grid' : 'flex',
				alignItems: 'center',
				gap: labelUp ? '10px' : '30px',
				margin: '10px',
				borderRadius: '20px !important',
			}}
		>
			<label
				style={{
					width: `${width}px`,
					textAlign: 'right',
					fontSize: '18px',
					color: `${color}`,
				}}
			>
				<strong>{name}:</strong>
			</label>
			<div style={{ maxWidth: '140px' }}>
				<Select
					value={selected}
					onChange={(selected) => handleChange(selected)}
					options={options}
					isMulti={multiple}
					styles={customStyles(selectWidth)}
				/>
			</div>
		</div>
	);
};

export default SelectInput;
