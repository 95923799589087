import moment from 'moment';
import { SORTING_TYPES } from '../constants/sortingTypes';

export const handleSort = ({ field, value, ordersData, sortingType }) => {
	return [...ordersData]?.sort((a, b) => {
		switch (sortingType) {
			case SORTING_TYPES.STRING:
				if (value === 1) {
					return a[field].localeCompare(b[field]);
				}

				if (value === -1) {
					return b[field].localeCompare(a[field]);
				}

				return 0;
			case SORTING_TYPES.NUMBER:
				if (value === 1) {
					return a[field] - b[field];
				}

				if (value === -1) {
					return b[field] - a[field];
				}

				return 0;
			case SORTING_TYPES.DATE:
				if (value === 1) {
					return moment(a[field]).diff(b[field]);
				}

				if (value === -1) {
					return moment(b[field]).diff(a[field]);
				}

				return 0;
			default:
				return 0;
		}
	});
};
