import { errorHandler } from '../common';
import Api from '../api';
import cleanObject from '../../common/functions/cleanObject';
const { axios: api } = Api;
const queryString = require('query-string');

export const getBooksFromCzechLogistic = (country, id) =>
	errorHandler(async () => {
		const data = await api.get(
			`/czech_logistic/books_by_country/?country=${country}&id=${id}`,
		);
		return { data: data.data };
	});

export const getAllCountryBooks = (country, shorts) =>
	errorHandler(async () => {
		const data = await api.get(
			`/czech_logistic/all_country_books/?` +
				queryString.stringify(cleanObject({ country, shorts }), {
					arrayFormat: 'bracket',
				}),
		);

		return { data: data.data };
	});

export const getBookWithParcels = (nr, shorts) =>
	errorHandler(async () => {
		const data = await api.get(
			`/czech_logistic/book_with_parcels/?` +
				queryString.stringify(cleanObject({ nr, shorts }), {
					arrayFormat: 'bracket',
				}),
		);

		return { data: data.data };
	});

export const removeDuplicateFromPaxy = (payload) =>
	errorHandler(async () => {
		const data = await api.post('czech_logistic/delete_parcel', payload);

		return { data: data.data };
	});

export const getLabelsPdf = (payload) =>
	errorHandler(async () => {
		const data = await api.post(`czech_logistic/labels_pdf`, payload);

		return { data: data.data };
	});

export const sendOrders = (payload) =>
	errorHandler(async () => {
		const data = await api.post('czech_logistic/send_order', { payload });
		return { data: data.data };
	});

export const checkPdf = (payload) =>
	errorHandler(async () => {
		const data = await api.post('czech_logistic/check_pdf', { payload });

		return { data: data.data };
	});
