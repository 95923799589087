export const Colors = {
	yellow: '#E7CF48',
	lightlightBlue: '#D8EEF6',
	lightBlue: '#00A0D6',
	darkBlue: '#035072',
	green: '#379298',
	orange: '#F0A14B',
	red: '#dc3545',
	violet: '#8A2BE2',
};
