import React from 'react';
import styled from 'styled-components';
import { Colors } from '../colors';

const Wrapper = styled.div`
	width: 300px;
	padding: 10px;
	border-radius: 10px;
	background-color: ${(props) => {
		if (props.type === 'success') {
			return Colors.green;
		}
		if (props.type === 'error') {
			return Colors.red;
		}
		if (props.type === 'info') {
			return Colors.orange;
		}
		return '#6d94ff';
	}};
	color: white;
	box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 0.8);
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	font-size: 20px;
`;

const Message = styled.div`
	width: 100%;
	max-height: 150px;
	text-align: center;
	text-overflow: ellipsis;
	overflow: hidden;
`;

const ICON = {
	error: <i className='fa-sharp fa-solid fa-circle-exclamation' />,
	success: <i className='fa-sharp fa-solid fa-circle-check' />,
	info: <i className='fa-solid fa-circle-info' />,
};

const Item = ({ message, removeMessage }) => {
	return (
		<Wrapper type={message.type}>
			<Message>{message.caption}</Message>

			<div
				style={{
					width: '10px',
					display: 'flex',
					justifyContent: 'right',
					color: 'red',
					cursor: 'pointer',
					fontSize: '20px',
					marginRight: '10px',
				}}
				onClick={() => removeMessage(message.id)}
			/>

			{ICON[message.type]}
		</Wrapper>
	);
};

export default Item;
