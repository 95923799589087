import React from 'react';
import styled from 'styled-components';

const Flex = styled.div`
	display: flex;
	align-items: center;
	margin: 10px;
	gap: ${(props) => `${props.gap}px`};

	label {
		width: fit-content;
	}
	input {
		width: fit-content;
		padding: 5px;
	}
`;

const Input = ({
	inputRef,
	value,
	name = true,
	required,
	disabled = false,
	type = 'text',
	width = 140,
	onChange,
	inputWidth = 140,
	textAlign = 'right',
	step,
	checked,
	padding = 0,
	color = '#000',
	size,
	id,
	inputHeight,
	defaultChecked,
	gap = 30,
	inputName,
}) => {
	return (
		<Flex gap={gap}>
			<label
				htmlFor={id}
				style={{
					width: `${width}px`,
					padding: `${padding}`,
					textAlign: `${textAlign}`,
					fontSize: '18px',
					color: `${color}`,
				}}
			>
				<strong>{name}:</strong>
			</label>
			<input
				name={inputName || id}
				id={id}
				type={type}
				ref={inputRef}
				defaultValue={value}
				required={required}
				disabled={disabled}
				onChange={onChange}
				placeholder='type...'
				step='any'
				checked={checked}
				defaultChecked={defaultChecked}
				style={{
					paddingLeft: '10px',
					width: `${inputWidth}px`,
					height: `${inputHeight}px`,
				}}
			/>
		</Flex>
	);
};

export default Input;
