import React from 'react';
import styled from 'styled-components';
import CardView from '../components/loginPage/Card/cardView';
import LoginForm from '../components/loginPage/LoginForm/loginForm';
import Welcometext from '../components/loginPage/WelcomeText/welcometext';

const Wrapper = styled.div`
	width: 100vw;
	height: 100vh;
	background-color: #d7edf6;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const LoginWrapper = styled.div`
	padding-top: 150px;
	width: 400px;
	height: 600px;
	position: absolute;
`;

const LoginPage = () => {
	return (
		<Wrapper>
			<CardView />
			<LoginWrapper>
				<Welcometext />
				<LoginForm />
			</LoginWrapper>
		</Wrapper>
	);
};

export default LoginPage;
