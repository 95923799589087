export const PARCEL_HEADERS = [
	'No',
	'Select',
	'Price',
	'Country code',
	// "IsLabelPrinted",
	'Full name',
	'RecipientCity',
	'RecipientEmail',
	'RecipientPostCode',
	'RecipientStreet',
	'RecipientTel',
	'TrackingNr',
	'Pdf',
];
