import momentBusinesDays from 'moment-business-days';
import moment from 'moment/moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import {
	getBooksFromCzechLogistic,
	sendOrders,
} from '../../API/repositories/czechlogistic';
import { getMarketsConfig } from '../../API/repositories/market';
import {
	getOrdersForMarket,
	getOrdersToSend,
	updateOrder,
	verifyOrder,
} from '../../API/repositories/order';
import { sendOrdersByParcelManager } from '../../API/repositories/pracelManager';
import userManager from '../../API/userManager';
import { Colors } from '../../common/colors';
import Input from '../../common/components/Input';
import Loading from '../../common/components/Loading';
import SelectInput from '../../common/components/SelectInput';
import Table from '../../common/components/Table';
import withRequestProvider from '../../common/hocs/withRequestProvider';
import { useRequestsContext } from '../../common/hooks/requestHook';
import Edit from '../../components/orders/Edit';
import SentView from '../../components/orders/SentView';
import { FIELDS } from './constants/fields';
import { SORTING_TYPES } from './constants/sortingTypes';
import { getHeaders } from './helpers/getHeaders';
import { getRaws } from './helpers/getRaws';
import { handleSort } from './helpers/handleSort';

const Wrapper = styled.div`
	padding: 20px;
`;

const Subtitle = styled.p`
	font-weight: bold;
	font-size: 16px;
	margin-left: 14px;
	color: ${Colors.darkBlue};
`;

const Flex = styled.div`
	display: flex;
	justify-content: left;
	align-items: center;
`;

const OrdersWrapper = styled.div`
	margin: 20px;
`;

const CouriersInfo = styled.span`
	font-size: 14px;
	font-weight: 400;
`;

const PARCELS_STEP = 35;

const handleCountDays = (howMany) => {
	return momentBusinesDays().businessAdd(howMany).format('YYYY-MM-DD');
};

const Orders = () => {
	const dateRef = useRef();
	const [bookNumbers, setBookNumbers] = useState();
	const [selectedBook, setSelectedBook] = useState();
	const [marketProducts, setMarketProducts] = useState([]);
	const [config, setConfig] = useState([]);
	let defaultShippingDate = handleCountDays(0);

	const [editData, setEditData] = useState(null);
	const [editShow, setEditShow] = useState(false);
	const [sentResultData, setSentResultData] = useState(null);
	const [sentResultShow, setSentResultShow] = useState(false);

	const { hasUnfilledRequest, makeRequest } = useRequestsContext();

	const [selectedParcels, setSelectedParcels] = useState([]);
	const [selectedCountry, setSelectedCountry] = useState(null);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [productOptions, setProductOptions] = useState(null);
	const [selectedPost, setSelectedPost] = useState(null);
	const [postOptions, setPostOptins] = useState(null);
	const [data, setData] = useState();
	const [isLoadingParcels, setIsLoadingParcels] = useState(false);
	const [sortings, setSortings] = useState({
		[FIELDS.COUNTRY.to]: 0,
		[FIELDS.CITY.to]: 0,
		[FIELDS.POSTAL_CODE.to]: -1,
		[FIELDS.STREET.to]: 0,
		[FIELDS.FULL_NAME.to]: 0,
		[FIELDS.PHONE.to]: 0,
		[FIELDS.EMAIL.to]: 0,
		[FIELDS.DELIVERY_DATE.to]: 0,
		[FIELDS.PRICE.to]: 0,
		[FIELDS.VARIANTS.to]: 0,
	});

	const handleSaveOrder = async (changeData) => {
		changeData.order[changeData.type] = changeData.text.replace(
			/\w\S*/g,
			(txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
		);
		changeData.order.curier = changeData.order.shipping.curier_lms_id;

		const response = await makeRequest(
			updateOrder.bind(null, changeData.order._id, changeData.order),
		);

		if (response.data) {
			await handleSearch();
		}
	};

	const handleSearch = async (e) => {
		if (e) {
			e.preventDefault();
		}

		await getProductsForMarket();

		const selectedCountryOption = config.find(
			(info) => info.country.value === selectedCountry.value,
		);

		const payload = Object();

		payload.countryCode = selectedCountry?.value;
		payload.productCode = selectedProduct?.value;
		payload.curier_lms_id = selectedPost?.value;
		payload.toUtc = dateRef.current?.value
			? dateRef.current?.value
			: handleCountDays(selectedCountryOption?.addDays);

		payload.toUtc = moment(payload.toUtc).toISOString();

		const response = await makeRequest(getOrdersToSend.bind(null, payload));
		const books = await makeRequest(
			getBooksFromCzechLogistic.bind(
				null,
				selectedCountry?.value,
				selectedPost?._id,
			),
		);

		if (response.data) {
			const filteredSelectedParcels = selectedParcels.filter((parcel) =>
				response.data.some((order) => order._id === parcel),
			);

			setSelectedParcels(() => filteredSelectedParcels);

			const ordersData = response.data?.map((order) => {
				const price = order.order_items.reduce(
					(acc, item) => item.price + acc,
					0,
				);
				const variants = order.order_items
					.map((item) => item.product_variant)
					.join(', ');

				order.price = price;
				order.variants = variants;

				const sortFields = Object.values(FIELDS).reduce((acc, field) => {
					acc[field.to] = field.formatter(order);
					return acc;
				}, {});

				return { ...order, price, variants, ...sortFields };
			});

			setData(
				handleSort({
					field: FIELDS.POSTAL_CODE.to,
					value: sortings[FIELDS.POSTAL_CODE.to],
					ordersData,
					sortingType: SORTING_TYPES.NUMBER,
				}),
			);

			if (books.data?.items) {
				setBookNumbers(() =>
					books.data.items?.map((book) => {
						return { label: book.nr, value: book.nr };
					}),
				);
				setSelectedBook(() => {
					return {
						label: books.data.items[0]?.nr,
						value: books.data.items[0]?.nr,
					};
				});
			}
		}
	};

	const handleSendParcels = async (e) => {
		e.preventDefault();
		if (!selectedParcels?.length) {
			return;
		}

		setIsLoadingParcels(() => true);

		await getProductsForMarket();

		const totalSent = {
			sentParcels: [],
			unSentParcels: [],
		};

		for (let i = 0; i < selectedParcels.length; i += PARCELS_STEP) {
			const payload = Object();

			payload.parcels = selectedParcels.slice(i, i + PARCELS_STEP);
			payload.book_number = selectedBook ? selectedBook.value : null;

			payload.country = selectedCountry.value;
			payload.product_id = selectedProduct.product_id;
			payload.default_product = selectedCountry.default_products.some(
				(product) => selectedProduct.value === product.short,
			);
			payload.post_id = selectedPost._id;
			payload.lms_sender = userManager.getUser().email;
			payload.post_provider = selectedPost.provider;

			const response = await makeRequest(
				sendOrdersByParcelManager.bind(null, payload),
			);

			totalSent.sentParcels.push(...(response?.data.sentParcels || []));
			totalSent.unSentParcels.push(...(response?.data.unSentParcels || []));
		}

		setSentResultData(() => totalSent);
		setSentResultShow(() => true);
		setIsLoadingParcels(() => false);
		setSelectedParcels(() => []);
		setData(() => null);
	};

	const handleSelectCountry = (selected) => {
		const selectedCountryOption = config.find(
			(info) => info.country.value === selected.value,
		);

		setSelectedCountry(() => selected);
		setProductOptions(() => selectedCountryOption.products);
		setSelectedProduct(() => selectedCountryOption.products[0]);
		setSelectedPost(() => selectedCountryOption.products[0].posts[0]);
		setPostOptins(() => selectedCountryOption.products[0].posts);

		dateRef.current.value = handleCountDays(selectedCountryOption?.addDays);
	};

	const handleSelectProduct = (selected) => {
		setSelectedProduct(() => selected);
		setSelectedPost(() => selected.posts[0]);
		setPostOptins(() => selected.posts);
	};

	const handleVerify = async (e, order) => {
		e.preventDefault();

		const response = await makeRequest(verifyOrder.bind(null, order));

		if (response.data) {
			await handleSearch();
		}
	};

	const handleMarkAllAsChecked = (e) => {
		e.preventDefault();
		setSelectedParcels(() => data?.map((element) => element._id));
	};

	const handleMarkAllAsUnChecked = (e) => {
		e.preventDefault();
		setSelectedParcels(() => []);
	};

	const getFormattedConfig = async () => {
		const configData = await makeRequest(getMarketsConfig);
		const newConfig = configData.data?.map((market) => ({
			country: {
				label: market.name,
				value: market.short,
				default_products: market._default_products,
			},
			products: [...market._default_products, ...market._products].map(
				(product) => ({
					label: product?.name,
					value: product.short,
					minPrice: product.min_price,
					product_id: product._id,
					posts: [...product._default_posts, ...product._post].map((post) => ({
						label: post?.name,
						value: post?._id,
						_id: post._id,
						curier_name: post.curier_name,
						provider: post.provider,
					})),
				}),
			),
			addDays: market.addDays,
		}));

		if (newConfig) {
			setSelectedCountry(() => newConfig[0].country);
			setSelectedProduct(() => newConfig[0]?.products[0]);
			setSelectedPost(() => newConfig[0]?.products[0].posts[0]);
			setProductOptions(() => newConfig[0]?.products);
			setPostOptins(() => newConfig[0]?.products[0].posts);
			dateRef.current.value = handleCountDays(newConfig[0]?.addDays);
			setConfig(() => newConfig);
		}
	};

	const getProductsForMarket = async () => {
		const selectedCountryOption = config.find(
			(info) => info.country.value === selectedCountry.value,
		);

		const payload = Object();
		payload.countryCode = selectedCountry?.value;
		payload.toUtc = dateRef.current?.value
			? dateRef.current?.value
			: handleCountDays(selectedCountryOption.addDays);
		payload.shorts = productOptions?.map((product) => product.value);
		payload.toUtc = moment(payload.toUtc).toISOString();

		const response = await makeRequest(getOrdersForMarket.bind(null, payload));

		setMarketProducts(() => response.data);
	};

	useEffect(() => {
		handleSearch();
	}, [editShow]);

	useEffect(() => {
		getFormattedConfig();
	}, []);

	useEffect(() => {
		if (selectedCountry) {
			getProductsForMarket();
		}
	}, [selectedCountry]);

	const headers = useMemo(
		() => getHeaders({ sortings, setSortings, data, setData }),
		[sortings, data],
	);
	const raws = useMemo(() => {
		if (data) {
			return getRaws({
				data,
				selectedParcels,
				handleVerify,
				setEditShow,
				setEditData,
				selectedProduct,
				setSelectedParcels,
				config,
				handleSaveOrder,
			});
		}
	}, [data, selectedParcels]);

	const getGroupedCouriersString = (ordersData) => {
		const groupedCouriers = ordersData.reduce((acc, orderData) => {
			if (!acc[orderData.shipping.curier_lms_id]) {
				acc[orderData.shipping.curier_lms_id] = 0;
			}

			acc[orderData.shipping.curier_lms_id]++;

			return acc;
		}, {});

		return Object.entries(groupedCouriers)
			.map(
				([courierId, amount]) => `${getCourierNameById(courierId)} - ${amount}`,
			)
			.join(', ');
	};

	const getCourierNameById = (courierId) => {
		return config
			.map((market) => market.products)
			.flat()
			.map((product) => product.posts)
			.flat()
			.find((post) => post._id === courierId)?.curier_name;
	};

	return (
		<Wrapper>
			{(hasUnfilledRequest(getOrdersToSend) ||
				hasUnfilledRequest(getMarketsConfig) ||
				hasUnfilledRequest(verifyOrder) ||
				hasUnfilledRequest(getOrdersForMarket) ||
				hasUnfilledRequest(getBooksFromCzechLogistic) ||
				hasUnfilledRequest(sendOrders) ||
				hasUnfilledRequest(updateOrder) ||
				isLoadingParcels) && <Loading />}
			{marketProducts?.map((marketProduct) => {
				return (
					<Subtitle key={marketProduct._id}>
						{marketProduct._product[0].name} - {marketProduct.total};{' '}
						<CouriersInfo>
							Couriers: {getGroupedCouriersString(marketProduct.data)}
						</CouriersInfo>
					</Subtitle>
				);
			})}
			<Flex>
				<SelectInput
					name='Country'
					options={config.map((info) => info.country)}
					selected={selectedCountry}
					setSelected={(selected) => handleSelectCountry(selected)}
					color={Colors.darkBlue}
				/>
				<SelectInput
					name='Product'
					selected={selectedProduct}
					options={productOptions}
					setSelected={(selected) => handleSelectProduct(selected)}
					color={Colors.darkBlue}
				/>
				<SelectInput
					name='Courier'
					selected={selectedPost}
					options={postOptions}
					setSelected={setSelectedPost}
					color={Colors.darkBlue}
				/>
				<Input
					inputRef={dateRef}
					name='Shipping date'
					type='date'
					value={defaultShippingDate}
					color={Colors.darkBlue}
				/>
				<button
					className='btn btn-warning'
					style={{ width: '80px', height: '40px', marginLeft: '80px' }}
					onClick={(e) => handleSearch(e)}
				>
					Search
				</button>
			</Flex>
			{data && data[0] && (
				<OrdersWrapper>
					<div
						style={{
							display: 'flex',
							justifyContent: 'left',
							gap: '20px',
							alignItems: 'center',
						}}
					>
						<button
							className='btn btn-warning'
							onClick={(e) => handleMarkAllAsChecked(e)}
						>
							Mark all
						</button>
						<button
							className='btn btn-secondary'
							onClick={(e) => handleMarkAllAsUnChecked(e)}
						>
							Unmark all{' '}
						</button>
						<SelectInput
							name='Book number'
							selected={selectedBook}
							options={bookNumbers}
							setSelected={setSelectedBook}
							width={140}
							selectWidth={240}
						/>
						<button
							className='btn btn-primary'
							style={{ marginLeft: '100px', fontWeight: 'bold' }}
							onClick={(e) => handleSendParcels(e)}
							disabled={!!hasUnfilledRequest(sendOrders)}
						>
							Send parcels
						</button>
						<h5 style={{ marginLeft: '20px' }}>
							Found: <strong> {data.length}</strong>
						</h5>
					</div>
					<div style={{ overflowX: 'scroll' }}></div>
				</OrdersWrapper>
			)}
			<div style={{ maxWidth: '100%', overflow: 'auto' }}>
				{data?.length > 0 && (
					<Table
						className='styled-table font-big'
						headers={headers}
						raws={raws}
					/>
				)}
			</div>
			{editShow && (
				<Edit
					data={editData}
					setEditShow={setEditShow}
					currierOptions={postOptions}
				/>
			)}
			{sentResultShow && (
				<SentView data={sentResultData} setSentResultShow={setSentResultShow} />
			)}
			{/* {showTextFormatter && <TextFormatter handleShow={setShowTextFormatter} data={showTextFormatter} handleSubmit={handleSaveOrder}  />} */}
		</Wrapper>
	);
};

export default withRequestProvider(Orders);
