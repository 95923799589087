import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { createMarket, updateMarket } from '../../API/repositories/market';
import Input from '../../common/components/Input';
import SelectInput from '../../common/components/SelectInput';
import Loading from '../../common/components/Loading';
import PopUp from '../../common/components/PopUp';
import { useRequestsContext } from '../../common/hooks/requestHook';
import {
	getMatched,
	convertToOptions,
} from '../../common/functions/settingsHelpers';
import { Colors } from '../../common/colors';

const Title = styled.h4`
	color: ${Colors.darkBlue};
	margin-bottom: 40px;
`;

const FLexRight = styled.div`
	display: flex;
	justify-content: right;
	align-items: center;
`;

const Wrapper = styled.div`
	padding-bottom: 52px;
`;

export const MarketForm = ({
	setShowMarketForm,
	market,
	products,
	loadData,
	currencies,
}) => {
	const [selectedProducts, setSelectedProducts] = useState(
		market ? convertToOptions(getMatched(market.products, products)) : [],
	);
	const [selectedCurrency, setSelectedCurrency] = useState(
		convertToOptions(currencies).find(
			(currency) => market?.currency === currency.value,
		),
	);
	const [defaultProducts, setDefaultProducts] = useState(
		market
			? convertToOptions(getMatched(market.default_products, products))
			: [],
	);
	const nameRef = useRef();
	const shortRef = useRef();
	const daysRef = useRef();
	const prefixTextRef = useRef();
	const suffixTextRef = useRef();

	const { hasUnfilledRequest = {}, makeRequest = {} } = useRequestsContext();

	const handleSubmit = async (e) => {
		e.preventDefault();
		const payload = Object();

		payload.name = nameRef.current.value;
		payload.short = shortRef.current.value;
		payload.addDays = daysRef.current.value;
		payload.products = selectedProducts.map((product) => product.value);
		payload.default_products = defaultProducts.map((product) => product.value);
		payload.currency = selectedCurrency.value;
		payload.prefix = prefixTextRef.current.value;
		payload.suffix = suffixTextRef.current.value;

		const response = market
			? await makeRequest(updateMarket.bind(null, market._id, payload))
			: await makeRequest(createMarket.bind(null, payload));

		if (response?.data) {
			setShowMarketForm(() => false);
			loadData();
		}
	};

	return (
		<>
			{hasUnfilledRequest(updateMarket, createMarket) && <Loading />}
			<PopUp setShow={setShowMarketForm}>
				<Title>{market ? 'Edit market' : 'Create market'}</Title>
				<form onSubmit={(e) => handleSubmit(e)}>
					<Wrapper>
						<Input
							width={170}
							name='Name'
							value={market?.name}
							inputRef={nameRef}
							inputWidth='220'
							required
							color={Colors.darkBlue}
						/>
						<Input
							width={170}
							inputRef={shortRef}
							name='Short'
							required
							inputWidth='220'
							value={market?.short}
							color={Colors.darkBlue}
						/>
						<Input
							width={170}
							inputRef={daysRef}
							name='Add days'
							required
							inputWidth='220'
							type='number'
							value={market?.addDays}
							color={Colors.darkBlue}
						/>
						<SelectInput
							name='Products'
							selected={selectedProducts}
							options={convertToOptions(products)}
							setSelected={setSelectedProducts}
							width='170'
							selectWidth='220'
							multiple
							required
							color={Colors.darkBlue}
						/>
						<SelectInput
							name='Currency'
							selected={selectedCurrency}
							options={convertToOptions(currencies)}
							setSelected={setSelectedCurrency}
							width='170'
							selectWidth='220'
							required
							color={Colors.darkBlue}
						/>
						<SelectInput
							name='Default products'
							selected={defaultProducts}
							options={convertToOptions(products)}
							setSelected={setDefaultProducts}
							width='170'
							selectWidth='220'
							multiple
							required
							color={Colors.darkBlue}
						/>
						<Input
							width={170}
							inputWidth={220}
							name='Prefix'
							color={Colors.darkBlue}
							inputRef={prefixTextRef}
							value={market?.prefix}
						/>
						<Input
							width={170}
							inputWidth={220}
							name='Suffix'
							color={Colors.darkBlue}
							inputRef={suffixTextRef}
							value={market?.suffix}
						/>
					</Wrapper>
					<FLexRight>
						<button type='submit' className='btn btn-warning'>
							Save
						</button>
					</FLexRight>
				</form>
			</PopUp>
		</>
	);
};
