import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import LoginPage from './pages/Login';
import api from './API/api';
import { useEffect } from 'react';
import userManager from './API/userManager';
import AdminView from './view-wrappers/AdminView';
import Jobs from './pages/Jobs';
import Users from './pages/Users';
import Reports from './pages/Reports';
import Settings from './pages/Settings';
import Markets from './pages/Markets';
import Couriers from './pages/Couriers';
import Products from './pages/Products';
import Books from './pages/Books';
import Flags from './pages/Flags';
import Currencies from './pages/Currencies';
import Orders from './pages/orders/Orders';
import Parcels from './pages/Parcels';
import { ROLES_ENUM } from './common/constants/roles';

function App() {
	const { tokenManager } = api;

	const { pathname } = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const user = tokenManager.getToken();
		if (!user) {
			navigate('/login');
			return;
		}
		if (user) {
			userManager.setUser(tokenManager.parseJwt(user));
		}

		if (pathname === '/' && user) {
			if (user.role === ROLES_ENUM.LABEL_SENDER) {
				navigate('/parcels');
			}

			navigate('/orders');
		}
	}, []);

	return (
		<div>
			<Routes>
				<Route path='/login' element={<LoginPage />} />
				<Route
					path='/orders'
					element={
						<AdminView>
							<Orders />
						</AdminView>
					}
				/>
				<Route
					path='/jobs'
					element={
						<AdminView>
							<Jobs />
						</AdminView>
					}
				/>
				<Route
					path='/users'
					element={
						<AdminView>
							<Users />
						</AdminView>
					}
				/>
				<Route
					path='/reports'
					element={
						<AdminView>
							<Reports />
						</AdminView>
					}
				/>
				<Route
					path='/books'
					element={
						<AdminView>
							<Books />
						</AdminView>
					}
				/>
				<Route
					path='/parcels'
					element={
						<AdminView>
							<Parcels />
						</AdminView>
					}
				/>
				<Route
					path='/settings'
					element={
						<AdminView>
							<Settings />
						</AdminView>
					}
				/>
				<Route
					path='/settings/markets'
					element={
						<AdminView>
							<Markets />
						</AdminView>
					}
				/>
				<Route
					path='/settings/products'
					element={
						<AdminView>
							<Products />
						</AdminView>
					}
				/>
				<Route
					path='/settings/posts'
					element={
						<AdminView>
							<Couriers />
						</AdminView>
					}
				/>
				<Route
					path='/settings/flags'
					element={
						<AdminView>
							<Flags />
						</AdminView>
					}
				/>
				<Route
					path='/settings/currencies'
					element={
						<AdminView>
							<Currencies />
						</AdminView>
					}
				/>
			</Routes>
		</div>
	);
}

export default App;
