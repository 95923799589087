import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	height: 100vh;

	width: 100vw;
	top: 0;
	left: 0;
	background: rgba(2, 80, 114, 0.78);
	z-index: 999;
`;

const Box = styled.div`
	min-width: 500px;
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
`;

const Card = styled.div`
	border-radius: 10px;
	border: 1px solid grey;
	background: white;
	padding: 10px 20px 50px 20px;
	max-height: 90vh;
	overflow-y: scroll;
`;

const PopUp = ({
	setShow,
	children,
	canOffByClickingBackground = true,
	maxWidth = '100%',
	top = 50,
}) => {
	return (
		<Wrapper>
			{canOffByClickingBackground && (
				<div
					onClick={() => setShow((prev) => !prev)}
					style={{ width: '100%', height: '100%' }}
				></div>
			)}
			<Box>
				<Card style={{ maxWidth: `${maxWidth}` }}>
					{' '}
					<div
						style={{
							width: '100%',
							display: 'flex',
							justifyContent: 'right',
							color: 'red',
							cursor: 'pointer',
						}}
						onClick={() => setShow((prev) => !prev)}
					>
						<i
							style={{ fontSize: '24px', marginRight: '-15px' }}
							className='fa fa-remove'
						/>
					</div>
					{children}
				</Card>
			</Box>
		</Wrapper>
	);
};

export default PopUp;
