import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import Input from '../common/components/Input';
import SelectInput from '../common/components/SelectInput';
import { useRequestsContext } from '../common/hooks/requestHook';
import withRequestProvider from '../common/hocs/withRequestProvider';
import { Colors } from '../common/colors';
import Loading from '../common/components/Loading';
import { handleCountDays } from '../common/functions/handleCountDays';
import { PDFDocument } from 'pdf-lib';
import Table from '../common/components/Table';
import pako from 'pako';
import MessageQueue, { useMessageQueue } from '../common/messageProvider';
import { POST_PROVIDERS } from '../components/posts/CourierForm';
import {
	getLabelsByProvider,
	getParcelsByProvider,
} from '../API/repositories/pracelManager';
import { findCuriersByProvider } from '../API/repositories/post';
import { createPDF } from '../components/parcels/functions/createPDF';
import { PARCEL_HEADERS } from '../components/parcels/constants/parcelHeaders';
import { NUMBER_OF_PARCELS_TO_SEND } from '../components/parcels/constants/numerOfParcelsToSend';

const Wrapper = styled.div`
	padding: 20px;
`;

const Flex = styled.div`
	display: flex;
	justify-content: left;
	align-items: center;
	margin-bottom: 18px;
`;

const FlexRight = styled.div`
	display: flex;
	justify-content: right;
	gap: 32px;
	max-width: 100vw;
`;

const CheckBox = styled.input`
	width: 30px;
	height: 30px;
	align-self: center;
	cursor: pointer;
`;

const ScrollableContainer = styled.div`
	max-width: 100vw;
	max-height: 70vh;
	margin-bottom: 24px;

	overflow: scroll;
`;

const PrintFormatWrapper = styled.form`
	display: flex;
	gap: 20px;
`;

const Parcels = () => {
	const [parcels, setParcels] = useState([]);
	const [selectedParcels, setSelectedParcels] = useState([]);
	const [parcelsLeft, setParcelsLeft] = useState(0);
	const [isFetchingLabels, setIsFetchingLabels] = useState(false);
	const [selectedProvider, setSelectedProvider] = useState(POST_PROVIDERS[1]);
	const [curiersOptions, setCuriersOptions] = useState([]);
	const [selectedCuriers, setSelectedCuriers] = useState([]);

	const [printFormat, setPrintFormat] = useState('A5');

	const sendingDateDefaultDate = handleCountDays(0);

	const sendingDateRef = useRef();

	const { hasUnfilledRequest, makeRequest } = useRequestsContext();
	const { messages, removeMessage, addMessage } = useMessageQueue();

	const fetchData = async () => {
		const payload = {
			post_id: selectedCuriers.value,
			date: sendingDateRef.current.value,
			provider: selectedProvider.value,
		};

		const response = await makeRequest(
			getParcelsByProvider.bind(null, payload),
		);

		if (response.data) {
			return setParcels(response?.data);
		}

		addMessage('No data', 'error');
	};

	const handleSearch = async (e) => {
		e && e.preventDefault();
		fetchData();
	};

	const handleGetPdfs = async (trackingNr, force = false) => {
		try {
			setIsFetchingLabels(() => true);
			const splittedTrackingNrs = [];

			if (trackingNr) {
				setParcelsLeft(1);

				const payload = {};
				payload.trackingNrs = [trackingNr];
				payload.format = printFormat;
				payload.provider = selectedProvider.value;
				payload.curier_id = selectedCuriers.value;

				const response = await makeRequest(
					getLabelsByProvider.bind(null, payload),
				);

				await createPDF(response?.data?.data);

				setParcelsLeft(() => 0);
				setIsFetchingLabels(() => false);
				return;
			}

			const allParcels = parcels.map((parcel) => parcel.waybill);
			const sortedParcels = allParcels.filter((parcel) =>
				selectedParcels.some((selectedParcel) => selectedParcel === parcel),
			);

			for (
				let i = 0;
				i < sortedParcels.length;
				i += NUMBER_OF_PARCELS_TO_SEND
			) {
				splittedTrackingNrs.push(
					[...sortedParcels].slice(i, i + NUMBER_OF_PARCELS_TO_SEND),
				);
			}

			const pdfToMerge = await PDFDocument.create();

			for (let i = 0; i < splittedTrackingNrs?.length; i++) {
				setParcelsLeft(
					() => sortedParcels.length - NUMBER_OF_PARCELS_TO_SEND * i,
				);

				const payload = {};
				payload.trackingNrs = splittedTrackingNrs[i];
				payload.format = printFormat;
				payload.provider = selectedProvider.value;
				payload.curier_id = selectedCuriers.value;

				const response = await makeRequest(
					getLabelsByProvider.bind(null, payload),
				);

				const uint8Array = new Uint8Array(response.data?.data);
				const decompressed = pako.inflate(uint8Array);
				const loadedPdf = await PDFDocument.load(decompressed);
				const copiedPages = await pdfToMerge.copyPages(
					loadedPdf,
					loadedPdf.getPageIndices(),
				);
				copiedPages.forEach((page) => pdfToMerge.addPage(page));
			}
			setParcelsLeft(() => 0);
			setIsFetchingLabels(() => false);

			const mergedPdf = await pdfToMerge.save();
			const file = new Blob([mergedPdf], { type: 'application/pdf' });
			const url = URL.createObjectURL(file);
			window.open(url, '_blank');
		} catch {
			setIsFetchingLabels(false);
		}
	};

	const handleParcelSelection = (trackingNr, state) => {
		if (state) {
			return setSelectedParcels((prev) => [...prev, trackingNr]);
		}

		setSelectedParcels((prev) => [
			...prev.filter((parcel) => parcel !== trackingNr),
		]);
	};

	const parcelRaws = useMemo(
		() =>
			parcels?.map((parcel, i) => (
				<tr key={parcel.waybill}>
					<td>{i + 1}</td>
					<td>
						<CheckBox
							checked={selectedParcels.includes(parcel.waybill)}
							type='checkbox'
							onChange={(e) => {
								handleParcelSelection(parcel.waybill, e.target.checked);
							}}
						/>
					</td>
					<td>{parcel.price}</td>
					<td>{parcel.country}</td>
					<td>{parcel.name}</td>
					<td>{parcel.city}</td>
					<td>{parcel.email}</td>
					<td>{parcel.postCode}</td>
					<td>{parcel.street}</td>
					<td>{parcel.phone}</td>
					<td>{parcel.waybill}</td>
					<td>
						<i
							className='fa-solid fa-2x fa-file-pdf animation-scale'
							style={{ color: Colors.darkBlue, cursor: 'pointer' }}
							onClick={() => handleGetPdfs(parcel.waybill)}
						/>
					</td>
				</tr>
			)),
		[parcels, selectedParcels],
	);

	const handleAllParcelsSelection = (isChecked) => {
		if (isChecked) {
			return setSelectedParcels(() => parcels.map((parcel) => parcel.waybill));
		}

		setSelectedParcels(() => []);
	};

	const loadData = async () => {
		const response = await makeRequest(
			findCuriersByProvider.bind(null, {
				provider: selectedProvider.value,
			}),
		);

		if (response.data) {
			const mapedCuriers = response.data.map((d) => ({
				label: d.name,
				value: d._id,
			}));

			setCuriersOptions(mapedCuriers);
			setSelectedCuriers(mapedCuriers[0]);
		}
	};

	useEffect(() => {
		if (selectedProvider) {
			loadData();
		}
	}, [selectedProvider]);

	return (
		<>
			{hasUnfilledRequest(findCuriersByProvider) && <Loading />}
			{(hasUnfilledRequest(getParcelsByProvider) || isFetchingLabels) && (
				<Loading amount={parcelsLeft || null} name='Parcels' />
			)}
			<MessageQueue removeMessage={removeMessage} messages={messages} />
			<Wrapper>
				<Flex>
					<SelectInput
						name='Provider'
						selected={selectedProvider}
						options={POST_PROVIDERS}
						setSelected={setSelectedProvider}
						color={Colors.darkBlue}
						selectWidth='180'
						width={60}
					/>
					<SelectInput
						name='Curiers'
						selected={selectedCuriers}
						options={curiersOptions}
						setSelected={setSelectedCuriers}
						color={Colors.darkBlue}
						selectWidth='180'
						width={120}
					/>
				</Flex>
				<Flex>
					<Input
						inputRef={sendingDateRef}
						name='Sending Date'
						type='date'
						value={sendingDateDefaultDate}
						color={Colors.darkBlue}
						textAlign='left'
						width='fit-content'
					/>

					<button
						className='btn btn-warning'
						style={{ margin: '0 20px' }}
						onClick={(e) => handleSearch(e)}
					>
						Search
					</button>
				</Flex>
			</Wrapper>
			<Wrapper>
				<ScrollableContainer>
					<Table
						className='styled-table'
						headers={PARCEL_HEADERS}
						raws={parcelRaws}
						style={{ margin: 0 }}
					/>
				</ScrollableContainer>
				<FlexRight>
					<PrintFormatWrapper onChange={(e) => setPrintFormat(e.target.value)}>
						<Input
							value='A5'
							name='A5'
							id='format_checkbox_a5'
							type='radio'
							checked={printFormat === 'A5'}
							width={2}
							inputWidth={30}
						/>

						<Input
							value='A4'
							width={2}
							inputWidth={30}
							checked={printFormat === 'A4'}
							name='A4'
							id='format_checkbox_a4'
							type='radio'
						/>
					</PrintFormatWrapper>
					<button
						className='btn btn-warning'
						onClick={() => handleAllParcelsSelection(true)}
					>
						SelectAll
					</button>
					<button
						className='btn btn-warning'
						onClick={() => handleAllParcelsSelection(false)}
					>
						UnselectAll
					</button>
					<button className='btn btn-warning' onClick={() => handleGetPdfs()}>
						Get pdfs
					</button>
				</FlexRight>
			</Wrapper>
		</>
	);
};

export default withRequestProvider(Parcels);
