import { errorHandler } from '../common';
import Api from '../api';
import cleanObject from '../../common/functions/cleanObject';
const { axios: api } = Api;
const queryString = require('query-string');

export const getOrdersToSend = (ordersToSend) =>
	errorHandler(async () => {
		const data = await api.get(
			'/crm/get_all_orders_to_shipping?' +
				queryString.stringify(cleanObject(ordersToSend), {
					arrayFormat: 'bracket',
				}),
		);
		return { data: data.data };
	});

export const getOrderByWaybill = (waybill) =>
	errorHandler(async () => {
		const data = await api.get(`/crm/get_order_by_waybill/${waybill}`);

		return { data: data.data };
	});

export const getOrdersForMarket = (orders) =>
	errorHandler(async () => {
		const data = await api.get(
			'/crm/get_orders_for_market?' +
				queryString.stringify(cleanObject(orders), {
					arrayFormat: 'bracket',
				}),
		);

		return { data: data.data };
	});

export const getOneOrder = (_id) =>
	errorHandler(async () => {
		const data = await api.get(`/crm/get_order_by_id/${_id}`);
		return { data: data.data };
	});

export const updateOrder = (_id, payload) =>
	errorHandler(async () => {
		const data = await api.patch(`/crm/update_contact_by_id/${_id}`, {
			payload,
		});
		return { data: data.data };
	});

export const verifyOrder = (order) =>
	errorHandler(async () => {
		const data = await api.post(`/crm/verify_order/${order._id}`, {
			...order,
		});
		return { data: data.data };
	});
