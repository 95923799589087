import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import api from '../../../API/api';
import userManager from '../../../API/userManager';
import { Colors } from '../../colors';
import { ROLES_ENUM } from '../../constants/roles';
import { NAVIGATION_CONFIG } from './helper/generateAccesNav';

const Wrapper = styled.div`
	height: 60px;
	background-color: ${Colors.darkBlue};
	display: flex;
	justify-content: space-between;
	padding: 0 20px;

	ul {
		display: flex;
		align-items: center;
		height: 100%;
		font-size: 18px;
		gap: 20px;
		color: white;
		font-weight: bold;

		li {
			cursor: pointer;
			list-style-type: none;
			text-decoration: none;
		}
	}
`;

const Navbar = () => {
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const user = userManager.getUser();

	const handleNavigation = (path) => {
		navigate(path);
	};

	const handleLogout = (e) => {
		e.preventDefault();
		userManager.removeUser();
		api.tokenManager.removeToken();
		navigate('/login');
	};

	return (
		<Wrapper>
			<ul>
				{NAVIGATION_CONFIG(pathname, handleNavigation)
					.filter((nc) => nc.access.includes(user?.role))
					.map((nc) => nc.value)}
			</ul>
			<ul>
				<li style={{ cursor: 'auto' }}>{user?.email}</li>
				<li className='animation-scale' onClick={(e) => handleLogout(e)}>
					Logout
				</li>
			</ul>
		</Wrapper>
	);
};

export default Navbar;
