export const ROLES = [
	{
		label: 'Admin',
		value: 'ADMIN',
	},
	{
		label: 'User',
		value: 'USER',
	},
	{
		label: 'Label Sender',
		value: 'LABEL_SENDER',
	},
];

export const ROLES_ENUM = {
	ADMIN: 'ADMIN',
	USER: 'USER',
	LABEL_SENDER: 'LABEL_SENDER',
};
