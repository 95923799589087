import { errorHandler } from '../common';
import Api from '../api';
const { axios: api } = Api;

export const createFlag = (flag) => {
	return errorHandler(async () => {
		const data = await api.post('/flag', flag);

		return { data: data.data };
	});
};

export const getFlags = () => {
	return errorHandler(async () => {
		const data = await api.get('/flag');

		return { data: data.data };
	});
};

export const getOneFlag = (_id) => {
	return errorHandler(async () => {
		const data = await api.get(`flag/${_id}`);

		return { data: data.data };
	});
};

export const updateFlag = (_id, flag) => {
	return errorHandler(async () => {
		const data = await api.patch('flag', flag);

		return { data: data.data };
	});
};
