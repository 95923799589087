import React from 'react';
import { Colors } from '../colors';

const EditIcon = ({ handleEdit }) => {
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				gap: '10px',
			}}
		>
			<i
				className='fa fa-edit animation-scale'
				onClick={handleEdit}
				style={{ cursor: 'pointer', color: Colors.darkBlue }}
			/>
		</div>
	);
};

export default EditIcon;
