import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
	background: rgba(2, 80, 114, 0.78);
	z-index: 999999;
`;

const LoadingWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 40px;
	text-align: center;
	margin-left: auto;
	margin-right: auto;

	span {
		display: inline-block;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		margin: 20px 10px 0 10px;
		background: white;
		animation: wave 1.3s linear infinite;

		&:nth-child(2) {
			animation-delay: -1.1s;
		}

		&:nth-child(3) {
			animation-delay: -0.9s;
		}
	}

	p {
		color: white;
		font-weight: bold;
		font-size: 48px;
	}
`;

const Loading = ({ amount, name }) => {
	return (
		<Wrapper>
			<LoadingWrapper>
				{amount && name && (
					<p>
						{name} left: {amount}
					</p>
				)}
				<span></span>
				<span></span>
				<span></span>
			</LoadingWrapper>
		</Wrapper>
	);
};

export default Loading;
