import React from 'react';
import styled from 'styled-components';

const Title = styled.h2`
	text-align: center;
`;

const SubTitle = styled.p`
	text-align: center;
`;

const Welcometext = () => {
	return (
		<>
			<Title>Welcome Back in LMS!</Title>
			<SubTitle>Login to continue</SubTitle>
		</>
	);
};

export default Welcometext;
