/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import Input from '../common/components/Input';
import SelectInput from '../common/components/SelectInput';
import { useRequestsContext } from '../common/hooks/requestHook';
import withRequestProvider from '../common/hocs/withRequestProvider';
import { Colors } from '../common/colors';
import moment from 'moment';
import {
	getAllCountryBooks,
	getBookWithParcels,
	getLabelsPdf,
	checkPdf,
	removeDuplicateFromPaxy,
} from '../API/repositories/czechlogistic';
import Loading from '../common/components/Loading';
import { getMarketsConfig } from '../API/repositories/market';
import PopUp from '../common/components/PopUp';
import { handleCountDays } from '../common/functions/handleCountDays';
import { PDFDocument } from 'pdf-lib';
import Table from '../common/components/Table';
import pako from 'pako';
import { getOrdersDataByWaybills, resetOrders } from '../API/repositories/crm';
import MessageQueue, { useMessageQueue } from '../common/messageProvider/';

const Wrapper = styled.div`
	padding: 20px;
`;

const FlexCenter = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Flex = styled.div`
	display: flex;
	justify-content: left;
	align-items: center;
	margin-bottom: 18px;
`;

const FlexRight = styled.div`
	display: flex;
	justify-content: right;
	gap: 32px;
`;

const GapFlex = styled.div`
	display: flex;
	gap: 32px;
	justify-content: center;
`;

const BooksTableWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const BooksWrapper = styled.div`
	margin: 20px;
`;

const CheckBox = styled.input`
	width: 30px;
	height: 30px;
	align-self: center;
	cursor: pointer;
`;

const ScrollableContainer = styled.div`
	max-width: 90vw;
	max-height: 70vh;
	margin-bottom: 24px;
	overflow: scroll;
`;

const Label = styled.label`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
`;

const TitleWrong = styled.h2`
	color: ${Colors.red};
`;

const TitleFine = styled.h2`
	color: ${Colors.green};
	margin-bottom: -15px;
`;

const PrintFormatWrapper = styled.form`
	display: flex;
	gap: 20px;
`;

const IconDelete = styled.i`
	color: ${Colors.red};
	cursor: pointer;
	position: relative;

	&:hover {
		&::after {
			content: 'Remove';
			color: black;
			background-color: lightgray;
			padding: 8px;
			border-radius: 16px;
			font-size: 12px;
			position: absolute;
			top: -30px;
			right: 50%;
			transform: translateX(50%);
		}
	}
`;

const NUMBER_OF_PARCELS_TO_SEND = 15;

const Books = () => {
	const [books, setBooks] = useState([]);
	const [selectedBook, setSelectedBook] = useState(null);
	const [booksToCheck, setBooksToCheck] = useState([]);
	const [selectedParcels, setSelectedParcels] = useState([]);
	const [isBookNameFilterApplied, setIsBookNameFilterApplied] = useState(true);

	const [wrongPdfData, setWrongPdfData] = useState(null);
	const [config, setConfig] = useState([]);
	const [selectedCountry, setSelectedCountry] = useState(null);
	const [selectedProducts, setSelectedProducts] = useState([]);
	const [productOptions, setProductOptions] = useState(null);
	const [parcelsLeft, setParcelsLeft] = useState(0);
	const [isFetchingLabels, setIsFetchingLabels] = useState(false);
	const [isCheckingBooks, setIsCheckingBooks] = useState(false);
	const [foundDuplicates, setFoundDuplicates] = useState(false);
	const [printFormat, setPrintFormat] = useState('A5');

	const fromDefaultDate = handleCountDays(0);
	const toDefaultDate = handleCountDays(0);

	const fromDateRef = useRef();
	const toDateRef = useRef();
	const { hasUnfilledRequest, makeRequest } = useRequestsContext();
	const { messages, removeMessage, addMessage } = useMessageQueue();

	const getFilteredBooks = (booksFromServer) => {
		return booksFromServer
			?.filter(
				(book) =>
					moment
						.utc(book.postingDate)
						.isSameOrAfter(moment.utc(fromDateRef.current.value)) &&
					moment
						.utc(book.postingDate)
						.isSameOrBefore(moment.utc(toDateRef.current.value)),
			)
			.filter((book) => {
				return isBookNameFilterApplied
					? selectedProducts.some((selectedProduct) =>
							book.comments?.includes(selectedProduct.label.toUpperCase()),
						)
					: true;
			});
	};

	const handleCheck = async (e, nr) => {
		e && e.preventDefault();
		setIsCheckingBooks(true);

		try {
			const wrongData = [];
			if (nr) {
				const response = await makeRequest(
					getBookWithParcels.bind(
						null,
						nr,
						selectedProducts.map((product) => product.value),
					),
				);
				const parcels = response?.data?.book?.parcels.map(
					(parcel) => parcel.trackingNr,
				);
				const splittedBook = [];

				for (let j = 0; j < parcels.length; j += 10) {
					splittedBook.push([...parcels].slice(j, j + 10));
				}

				for (let y = 0; y < splittedBook.length; y++) {
					const payload = {};
					payload.bookNr = response.data.book.nr;
					payload.trackingNrs = splittedBook[y];
					const checkResponse = await makeRequest(checkPdf.bind(null, payload));

					if (checkResponse.data && checkResponse.data[0]) {
						wrongData.push(...checkResponse.data);
					}
				}
			} else {
				for (let i = 0; i < booksToCheck.length; i++) {
					const response = await makeRequest(
						getBookWithParcels.bind(
							null,
							booksToCheck[i],
							selectedProducts.map((product) => product.value),
						),
					);
					const parcels = response?.data?.book?.parcels.map(
						(parcel) => parcel.trackingNr,
					);
					const splittedBook = [];

					for (let j = 0; j < parcels.length; j += 10) {
						splittedBook.push([...parcels].slice(j, j + 10));
					}

					for (let y = 0; y < splittedBook.length; y++) {
						const payload = {};
						payload.bookNr = response.data.book.nr;
						payload.trackingNrs = splittedBook[y];
						const checkResponse = await makeRequest(
							checkPdf.bind(null, payload),
						);

						if (checkResponse.data && checkResponse.data[0]) {
							wrongData.push(...checkResponse.data);
						}
					}
				}
			}

			setWrongPdfData(() => wrongData);
			setIsCheckingBooks(false);
		} catch {
			setIsCheckingBooks(false);
		}
	};

	const handleSearch = async (e) => {
		e && e.preventDefault();

		const response = await makeRequest(
			getAllCountryBooks.bind(
				null,
				selectedCountry?.value,
				selectedProducts.map((product) => product.value),
			),
		);
		const filteredBooks = getFilteredBooks(response?.data?.items);

		setBooks(filteredBooks);
	};

	const handleSelectCountry = (selected) => {
		const selectedCountryOption = config.find(
			(info) => info?.country.value === selected.value,
		);

		setSelectedCountry(() => selected);
		setProductOptions(() => selectedCountryOption.products);
		setSelectedProducts(() => [selectedCountryOption.products[0]]);
	};

	const handleResetOrders = async () => {
		const response = await makeRequest(resetOrders.bind(null, selectedParcels));

		if (response?.data) {
			return addMessage('Success', 'success');
		}

		return addMessage('Something went wrong', 'error');
	};

	const handleSelectUnprintedParcels = () => {
		const unprinted = selectedBook.parcels.filter(
			(parcel) => !parcel.isLabelPrinted,
		);

		setSelectedParcels(() => unprinted.map((parcel) => parcel.trackingNr));
	};

	const handleAllParcelsSelection = (isChecked) => {
		if (isChecked) {
			setSelectedParcels(() =>
				selectedBook.parcels.map((parcel) => parcel.trackingNr),
			);
		} else {
			setSelectedParcels(() => []);
		}
	};

	const handleAllBooksToCheckSelection = (isChecked) => {
		if (isChecked) {
			setBooksToCheck(() => books.map((book) => book.nr));
		} else {
			setBooksToCheck(() => []);
		}
	};

	const handleBookClose = () => {
		setSelectedParcels(() => []);
		setSelectedBook(() => null);
	};

	const handleGetPdfs = async (trackingNr, force = false) => {
		try {
			setIsFetchingLabels(() => true);
			const splittedTrackingNrs = [];
			if (trackingNr) {
				const payload = {};
				setParcelsLeft(1);
				payload.trackingNrs = [trackingNr];
				payload.format = printFormat;
				const response = await makeRequest(getLabelsPdf.bind(null, payload));
				const uint8Array = new Uint8Array(response.data?.data);
				const decompressed = pako.inflate(uint8Array);
				const file = new Blob([decompressed], { type: 'application/pdf' });
				const url = await URL.createObjectURL(file);
				window.open(url, '_blank');
				setParcelsLeft(() => 0);
				setIsFetchingLabels(() => false);
				return;
			}
			if (!force) {
				const phoneNumbersMap = new Map();
				const duplicates = [];
				selectedParcels.forEach((parcelId) => {
					const { recipientTel, trackingNr } = selectedBook.parcels.find(
						(bookParcel) => bookParcel.trackingNr === parcelId,
					);
					if (!phoneNumbersMap.get(recipientTel)) {
						phoneNumbersMap.set(recipientTel, []);
					}
					const currentValue = phoneNumbersMap.get(recipientTel);
					const newValue = [...currentValue, trackingNr];
					phoneNumbersMap.set(recipientTel, newValue);
				});
				phoneNumbersMap.forEach((value) => {
					if (value.length > 1) {
						duplicates.push(...value);
					}
				});
				if (duplicates.length) {
					const ordersResponse = await makeRequest(
						getOrdersDataByWaybills.bind(null, duplicates),
					);

					if (!ordersResponse?.data) {
						setIsFetchingLabels(() => false);
						return;
					}

					const toShowOrders = [];

					// wyswietlamy tylko wtedy kiedy sa faktycznie duble u nas w systemie a nie w super systemie paxy :)

					phoneNumbersMap.forEach((value) => {
						const orderFromCRM = ordersResponse.data.orders.filter((order) =>
							value.includes(order?.shipping?.waybill_number),
						);

						if (orderFromCRM.length > 1) {
							toShowOrders.push(
								...orderFromCRM.map((ofc) => ({
									[ofc.shipping?.waybill_number]: orderFromCRM,
								})),
							);
						}
					});

					setFoundDuplicates(() => toShowOrders);
					setIsFetchingLabels(() => false);
					return;
				}
			}

			const allParcels = selectedBook.parcels.map(
				(parcel) => parcel.trackingNr,
			);
			const sortedParcels = allParcels.filter((parcel) =>
				selectedParcels.some((selectedParcel) => selectedParcel === parcel),
			);

			for (
				let i = 0;
				i < sortedParcels.length;
				i += NUMBER_OF_PARCELS_TO_SEND
			) {
				splittedTrackingNrs.push(
					[...sortedParcels].slice(i, i + NUMBER_OF_PARCELS_TO_SEND),
				);
			}
			const pdfToMerge = await PDFDocument.create();
			for (let i = 0; i < splittedTrackingNrs?.length; i++) {
				setParcelsLeft(
					() => sortedParcels.length - NUMBER_OF_PARCELS_TO_SEND * i,
				);

				const payload = {};
				payload.trackingNrs = splittedTrackingNrs[i];
				payload.format = printFormat;
				payload.bookNr = selectedBook.nr;
				const response = await makeRequest(getLabelsPdf.bind(null, payload));
				const uint8Array = new Uint8Array(response.data?.data);
				const decompressed = pako.inflate(uint8Array);
				const loadedPdf = await PDFDocument.load(decompressed);
				const copiedPages = await pdfToMerge.copyPages(
					loadedPdf,
					loadedPdf.getPageIndices(),
				);
				copiedPages.forEach((page) => pdfToMerge.addPage(page));
			}
			setParcelsLeft(() => 0);
			setIsFetchingLabels(() => false);
			const mergedPdf = await pdfToMerge.save();
			const file = new Blob([mergedPdf], { type: 'application/pdf' });
			const url = URL.createObjectURL(file);
			window.open(url, '_blank');
		} catch {
			setIsFetchingLabels(false);
		}
	};

	const handleParcelSelection = (trackingNr, state) => {
		if (state) {
			setSelectedParcels((prev) => [...prev, trackingNr]);
		} else {
			setSelectedParcels((prev) => [
				...prev.filter((parcel) => parcel !== trackingNr),
			]);
		}
	};

	const handleBookToCheckSelection = (nr, state) => {
		if (state) {
			setBooksToCheck((prev) => [...prev, nr]);
		} else {
			setBooksToCheck((prev) => [...prev.filter((bookNr) => bookNr !== nr)]);
		}
	};

	const handleBookSelection = async (nr) => {
		const response = await makeRequest(
			getBookWithParcels.bind(
				null,
				nr,
				selectedProducts.map((product) => product.value),
			),
		);

		setSelectedBook(response.data?.book);
	};

	const deleteDuplicate = async (trackingNr) => {
		const payload = {};

		payload.shorts = selectedProducts.map((product) => product.value);
		payload.trackingNr = trackingNr;

		const response = await makeRequest(
			removeDuplicateFromPaxy.bind(null, payload),
		);

		if (response.data && response.data[0]) {
			await handleSearch();

			handleBookSelection(selectedBook.nr);
			setFoundDuplicates(() => null);
		}
	};

	const getFormattedConfig = async () => {
		const configData = await makeRequest(getMarketsConfig);
		const newConfig = configData.data.map((market) => ({
			country: {
				label: market.name,
				value: market.short,
				default_products: market._default_products,
			},
			products: [...market._default_products, ...market._products].map(
				(product) => ({
					label: product.name,
					value: product.short,
					minPrice: product.min_price,
					product_id: product._id,
				}),
			),
			addDays: market.addDays,
		}));

		setSelectedCountry(() => newConfig[0]?.country);
		setSelectedProducts(() => [newConfig[0]?.products[0]]);
		setProductOptions(() => newConfig[0]?.products);
		setConfig(() => newConfig);
	};

	useEffect(() => {
		getFormattedConfig();
	}, []);

	const duplicatesHeaders = [
		'No',
		'TrackingNr',
		'Full name',
		'Phone number',
		'Confirmation date',
		'Actions',
	];

	const duplicatesRaws = useMemo(() => {
		if (foundDuplicates) {
			return foundDuplicates.map((duplicate, i) => {
				const trackingNr = Object.keys(duplicate)[0];
				const potentialOrderData = Object.values(duplicate)[0];
				return (
					<tr key={trackingNr}>
						<td>{i + 1}</td>
						<td>{trackingNr}</td>
						<td>{potentialOrderData?.contact.full_name || '----'}</td>
						<td>
							{potentialOrderData?.contact.phone_number_for_courier ||
								potentialOrderData?.contact.phone_number ||
								'----'}
						</td>
						<td>
							{potentialOrderData?.confirmation_date
								? moment(potentialOrderData?.confirmation_date).format(
										'YYYY-MM-DD HH:mm',
									)
								: '----'}
						</td>
						<td>
							<IconDelete
								onClick={() => deleteDuplicate(trackingNr)}
								className='fa fa-trash'
							/>
						</td>
					</tr>
				);
			});
		}
	}, [foundDuplicates]);

	const wrongPdfDataHeaders = ['No', 'BookNr', 'TrackingNr', 'Name'];
	const wrongPdfDataRaws = useMemo(() => {
		if (wrongPdfData) {
			return wrongPdfData?.map((wrongPerson, i) => (
				<tr key={wrongPerson.trackingNr}>
					<td>{i + 1}</td>
					<td>{wrongPerson.bookNr}</td>
					<td>{wrongPerson.trackingNr}</td>
					<td>{wrongPerson.name}</td>
				</tr>
			));
		}
	}, [wrongPdfData]);

	const booksHeaders = [
		'No',
		'Check',
		'nr',
		'Comment',
		'Opened',
		'Postring date',
		'Options',
	];
	const booksRaws = useMemo(
		() =>
			books?.map((book, i) => (
				<tr key={book.nr}>
					<td>{i + 1}</td>
					<td>
						<CheckBox
							checked={booksToCheck.includes(book.nr)}
							type='checkbox'
							onChange={(e) => {
								handleBookToCheckSelection(book.nr, e.target.checked);
							}}
						/>
					</td>
					<td>{book.nr}</td>
					<td>{book.comments}</td>
					<td>{book.isOpen ? 'True' : 'False'}</td>
					<td>{book.postingDate}</td>
					<td>
						<GapFlex>
							<i
								className='fa fa-2x fa-edit animation-scale'
								onClick={() => handleBookSelection(book.nr)}
								style={{ cursor: 'pointer', color: Colors.darkBlue }}
							/>

							<i
								className='fa-solid fa-2x fa-check animation-scale'
								onClick={(e) => handleCheck(e, book.nr)}
								style={{ cursor: 'pointer', color: Colors.darkBlue }}
							/>
						</GapFlex>
					</td>
				</tr>
			)),
		[books, booksToCheck],
	);

	const parcelHeaders = [
		'No',
		'Select',
		'BookNr',
		'Courier',
		'Courier code',
		'Price',
		'Country code',
		'IsLabelPrinted',
		'Full name',
		'RecipientCity',
		'RecipientEmail',
		'RecipientPostCode',
		'RecipientStreet',
		'RecipientTel',
		'InternalNr',
		'Status',
		'TrackingNr',
		'Pdf',
	];

	const parcelRaws = useMemo(
		() =>
			selectedBook?.parcels.map((parcel, i) => (
				<tr key={parcel.trackingNr}>
					<td>{i + 1}</td>
					<td>
						<CheckBox
							checked={selectedParcels.includes(parcel.trackingNr)}
							type='checkbox'
							onChange={(e) => {
								handleParcelSelection(parcel.trackingNr, e.target.checked);
							}}
						/>
					</td>
					<td>{parcel.bookNr}</td>
					<td>{parcel.carrier}</td>
					<td>{parcel.carrierCode}</td>
					<td>{parcel.cod}</td>
					<td>{parcel.countryCode}</td>
					<td>{parcel.isLabelPrinted ? 'True' : 'False'}</td>
					<td>{parcel.recipientName}</td>
					<td>{parcel.recipientCity}</td>
					<td>{parcel.recipientEmail}</td>
					<td>{parcel.recipientPostCode}</td>
					<td>{parcel.recipientStreet}</td>
					<td>{parcel.recipientTel}</td>
					<td>{parcel.internalNr}</td>
					<td>{parcel.status}</td>
					<td>{parcel.trackingNr}</td>
					<td>
						<i
							className='fa-solid fa-2x fa-file-pdf animation-scale'
							style={{ color: Colors.darkBlue, cursor: 'pointer' }}
							onClick={() => handleGetPdfs(parcel.trackingNr)}
						/>
					</td>
				</tr>
			)),
		[selectedBook, selectedParcels],
	);

	return (
		<>
			<Wrapper>
				{((hasUnfilledRequest(getAllCountryBooks) ||
					hasUnfilledRequest(removeDuplicateFromPaxy) ||
					hasUnfilledRequest(getMarketsConfig) ||
					hasUnfilledRequest(checkPdf) ||
					hasUnfilledRequest(isCheckingBooks)) && <Loading />) ||
					((hasUnfilledRequest(getLabelsPdf) || isFetchingLabels) && (
						<Loading amount={parcelsLeft || null} name='Parcels' />
					))}
				<MessageQueue removeMessage={removeMessage} messages={messages} />
				<Flex>
					<SelectInput
						name='Country'
						options={config.map((info) => info.country)}
						selected={selectedCountry}
						setSelected={(selected) => handleSelectCountry(selected)}
						color={Colors.darkBlue}
					/>
					<SelectInput
						name='Product'
						selected={selectedProducts}
						options={productOptions}
						setSelected={setSelectedProducts}
						color={Colors.darkBlue}
						selectWidth='210'
						multiple
					/>
				</Flex>

				<Flex>
					<Input
						inputRef={fromDateRef}
						name='From'
						type='date'
						value={fromDefaultDate}
						color={Colors.darkBlue}
						textAlign='left'
						width='fit-content'
						padding='0px 6px'
					/>
					<Input
						inputRef={toDateRef}
						name='To'
						type='date'
						value={toDefaultDate}
						color={Colors.darkBlue}
						textAlign='left'
						width='fit-content'
					/>

					<button
						className='btn btn-warning'
						style={{ margin: '0 20px' }}
						onClick={(e) => handleSearch(e)}
					>
						Search
					</button>
					<Label>
						<CheckBox
							checked={isBookNameFilterApplied}
							type='checkbox'
							style={{ margin: '10px' }}
							onChange={() => setIsBookNameFilterApplied((prev) => !prev)}
						/>
						<p>Apply product name filter</p>
					</Label>
				</Flex>
				{books?.length > 0 && (
					<BooksWrapper>
						<BooksTableWrapper>
							<div style={{ overflowX: 'scroll', width: '100%' }}>
								<Table
									className='styled-table font big'
									style={{ minWidth: '100%' }}
									raws={booksRaws}
									headers={booksHeaders}
									keyName='nr'
								/>
							</div>
						</BooksTableWrapper>

						<FlexRight>
							<button
								className='btn btn-warning'
								onClick={() => handleAllBooksToCheckSelection(true)}
							>
								SelectAll
							</button>

							<button
								className='btn btn-warning'
								onClick={() => handleAllBooksToCheckSelection(false)}
							>
								UnselectAll
							</button>

							<button
								className='btn btn-warning'
								onClick={(e) => handleCheck(e)}
							>
								Check books
							</button>
						</FlexRight>
					</BooksWrapper>
				)}
			</Wrapper>
			{selectedBook && (
				<PopUp setShow={handleBookClose}>
					<Wrapper>
						<ScrollableContainer>
							<Table
								className='styled-table'
								headers={parcelHeaders}
								raws={parcelRaws}
								style={{ margin: 0 }}
							/>
						</ScrollableContainer>
						<FlexRight>
							<PrintFormatWrapper
								onChange={(e) => setPrintFormat(e.target.value)}
							>
								<Input
									value='A5'
									name='A5'
									id='format_checkbox_a5'
									type='radio'
									checked={printFormat === 'A5'}
									width={2}
									inputWidth={30}
								/>

								<Input
									value='A4'
									width={2}
									inputWidth={30}
									checked={printFormat === 'A4'}
									name='A4'
									id='format_checkbox_a4'
									type='radio'
								/>
							</PrintFormatWrapper>
							<button
								onClick={() => handleResetOrders()}
								className='btn btn-danger'
							>
								Reset orders
							</button>
							<button
								className='btn btn-warning'
								onClick={() => handleSelectUnprintedParcels()}
							>
								SelectUnprinted
							</button>
							<button
								className='btn btn-warning'
								onClick={() => handleAllParcelsSelection(true)}
							>
								SelectAll
							</button>
							<button
								className='btn btn-warning'
								onClick={() => handleAllParcelsSelection(false)}
							>
								UnselectAll
							</button>
							<button
								className='btn btn-warning'
								onClick={() => handleGetPdfs()}
							>
								Get pdfs
							</button>
						</FlexRight>
					</Wrapper>
				</PopUp>
			)}
			{!!foundDuplicates?.length && (
				<PopUp setShow={setFoundDuplicates}>
					<Wrapper>
						<TitleWrong>Found duplicates</TitleWrong>
						<Table
							className='styled-table wrong-table'
							headers={duplicatesHeaders}
							raws={duplicatesRaws}
						/>

						<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
							<button
								className='btn btn-warning'
								onClick={() => handleGetPdfs(null, true)}
							>
								Get pdfs with duplicates
							</button>
						</div>
					</Wrapper>
				</PopUp>
			)}
			{wrongPdfData && wrongPdfData.length && (
				<PopUp setShow={setWrongPdfData}>
					<TitleWrong>Found errors</TitleWrong>
					<Table
						className='styled-table wrong-table'
						headers={wrongPdfDataHeaders}
						raws={wrongPdfDataRaws}
					/>
				</PopUp>
			)}
			{wrongPdfData && !wrongPdfData.length && (
				<PopUp setShow={setWrongPdfData}>
					<FlexCenter>
						<TitleFine>Everything is okay</TitleFine>
					</FlexCenter>
				</PopUp>
			)}
		</>
	);
};

export default withRequestProvider(Books);
