import { countPrice, getOrderItems } from '../helpers/orderCalculations';
import { SORTING_TYPES } from './sortingTypes';

export const FIELDS = {
	COUNTRY: {
		to: 'country_sort',
		original: 'country',
		type: SORTING_TYPES.STRING,
		formatter: (value) => value.country,
	},
	CITY: {
		to: 'city_sort',
		original: 'city',
		type: SORTING_TYPES.STRING,
		formatter: (value) => value.city,
	},
	POSTAL_CODE: {
		to: 'postal_code_sort',
		original: 'postal_code',
		type: SORTING_TYPES.NUMBER,
		formatter: (value) => parseInt(value.postal_code?.replace(/\D/g, '')),
	},
	STREET: {
		to: 'street_sort',
		original: 'street',
		type: SORTING_TYPES.STRING,
		formatter: (value) => value.street,
	},
	FULL_NAME: {
		to: 'full_name_sort',
		original: 'full_name',
		type: SORTING_TYPES.STRING,
		formatter: (value) => value.full_name,
	},
	PHONE: {
		to: 'phone_sort',
		original: 'phone',
		type: SORTING_TYPES.NUMBER,
		formatter: (value) => parseInt(value.phone?.default?.replace(/\D/g, '')),
	},
	EMAIL: {
		to: 'email_sort',
		original: 'email',
		type: SORTING_TYPES.STRING,
		formatter: (value) => value?.email,
	},
	DELIVERY_DATE: {
		to: 'suggested_delivery_date_sort',
		original: 'suggested_delivery_date',
		type: SORTING_TYPES.DATE,
		formatter: (value) => new Date(value?.suggested_delivery_date),
	},
	PRICE: {
		to: 'price_sort',
		original: 'price',
		type: SORTING_TYPES.NUMBER,
		formatter: (value) => countPrice(getOrderItems(value)),
	},
	VARIANTS: {
		to: 'variants_sort',
		original: 'variants',
		type: SORTING_TYPES.STRING,
		formatter: (value) =>
			getOrderItems(value)
				.map((item) => item.product_variant)
				.join(', '),
	},
};
