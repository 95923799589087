import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Api from '../../../API/api';
import { login } from '../../../API/repositories/user';
import userManager from '../../../API/userManager';
import withRequestProvider from '../../../common/hocs/withRequestProvider';
import { useRequestsContext } from '../../../common/hooks/requestHook';
import './loginForm.style.css';
import { ROLES_ENUM } from '../../../common/constants/roles';

const { tokenManager } = Api;

const Flex = styled.div`
	display: flex;
	align-items: center;
`;

const IconWrapper = styled.div`
	margin-top: 20px;
	display: flex;
	align-items: center;
	color: white;
	background-color: #025072;
	font-size: 25px;
	width: 40px;
	justify-content: center;
	text-align: center;
	height: 50px;
	border-radius: 0px 10px 10px 0px;
`;

const LoginForm = () => {
	const navigate = useNavigate();
	let emailRef = useRef();
	let passwordRef = useRef();

	const { makeRequest } = useRequestsContext();

	const submitHandler = async (e) => {
		e.preventDefault();
		const email = emailRef.current.value.replace(' ', '');
		const password = passwordRef.current.value.replace(' ', '');

		const response = await makeRequest(login.bind(null, email, password));

		if (response.data) {
			if (response.data) {
				const payload = tokenManager.parseJwt(response.data);
				userManager.setUser(payload);

				const user = userManager.getUser();
				console.log(user);

				if (user.role === ROLES_ENUM.LABEL_SENDER) {
					return navigate('/parcels');
				}

				navigate('/orders');
			}

			// if (response.error) {
			//   if (messages.length === 0) {
			//     if (response.error === "user doesnt exist")
			//       addMessage(`Taki uzytkownik nie istnieje!`, "error");
			//     if (response.error === "incorect password")
			//       addMessage(`Niepoprawne haslo!`, "error");
			//   }
			// }
		}
	};

	return (
		<form className='form' onSubmit={(e) => submitHandler(e)}>
			<Flex>
				<input
					ref={emailRef}
					className='input'
					type='email'
					placeholder='Enter email'
					required
				/>
				<IconWrapper>
					<i className='bi bi-person-fill'></i>
				</IconWrapper>
			</Flex>
			<Flex>
				<input
					ref={passwordRef}
					className='input'
					type='password'
					placeholder='Enter password'
					required
				/>
				<IconWrapper>
					<i className='bi bi-key-fill '></i>
				</IconWrapper>
			</Flex>
			<button className='button btn' type='submit'>
				LOGIN
			</button>
		</form>
	);
};

export default withRequestProvider(LoginForm);
