import { errorHandler } from '../common';
import Api from '../api';
import cleanObject from '../../common/functions/cleanObject';
const { axios: api } = Api;
const queryString = require('query-string');

export const createPost = (post) => {
	return errorHandler(async () => {
		const data = await api.post('/post', post);

		return { data: data.data };
	});
};

export const findCuriersByProvider = (payload) =>
	errorHandler(async () => {
		const data = await api.get(
			`/post/get-curiers-by-provider/?` +
				queryString.stringify(cleanObject(payload), {
					arrayFormat: 'bracket',
				}),
		);

		return { data: data.data };
	});

export const getPosts = () => {
	return errorHandler(async () => {
		const data = await api.get('/post');

		return { data: data.data };
	});
};

export const getOnePost = (_id) => {
	return errorHandler(async () => {
		const data = await api.get(`post/${_id}`);

		return { data: data.data };
	});
};

export const updatePost = (_id, post) => {
	return errorHandler(async () => {
		const data = await api.patch(`post/${_id}`, post);

		return { data: data.data };
	});
};

export const deletePost = (_id) => {
	return errorHandler(async () => {
		const data = await api.delete(`post/${_id}`);

		return { data: data.data };
	});
};
