import { errorHandler } from '../common';
import Api from '../api';
const { axios: api } = Api;

export const createMarket = (market) => {
	return errorHandler(async () => {
		const data = await api.post('/market', market);

		return { data: data.data };
	});
};

export const getMarkets = async () => {
	return errorHandler(async () => {
		const data = await api.get('/market');

		return { data: data.data };
	});
};

export const getMarketsConfig = async () => {
	return errorHandler(async () => {
		const data = await api.get('/market/market/settings');

		return { data: data.data };
	});
};

export const getOneMarket = (_id) => {
	return errorHandler(async () => {
		const data = await api.get(`market/${_id}`);

		return { data: data.data };
	});
};

export const updateMarket = (_id, market) => {
	return errorHandler(async () => {
		const data = await api.patch(`market/${_id}`, market);

		return { data: data.data };
	});
};

export const deleteMarket = (_id) => {
	return errorHandler(async () => {
		const data = await api.delete(`market/${_id}`);

		return { data: data.data };
	});
};
