import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { createPost, updatePost } from '../../API/repositories/post';
import Input from '../../common/components/Input';
import Loading from '../../common/components/Loading';
import PopUp from '../../common/components/PopUp';
import { useRequestsContext } from '../../common/hooks/requestHook';
import { Colors } from '../../common/colors';
import SelectInput from '../../common/components/SelectInput';

const FLexRight = styled.div`
	display: flex;
	justify-content: right;
	align-items: center;
	margin: 60px 0 -30px 0;
`;

const Title = styled.h4`
	color: ${Colors.darkBlue};
`;

export const POST_PROVIDERS = [
	{
		label: 'PAXY',
		value: 'paxy',
	},
	{
		label: 'Fan Curier',
		value: 'fan_curier',
	},
];

export const CourierForm = ({ setShowPostForm, post, loadData }) => {
	const nameRef = useRef();
	const shortRef = useRef();
	const curierNameRef = useRef();
	const keyRef = useRef();
	const tokenRef = useRef();
	const trackingLinkRef = useRef();
	const scaleRef = useRef();

	const [selectedProvider, setSelectedProvider] = useState(
		POST_PROVIDERS.find((p) => p.value === post?.provider) || POST_PROVIDERS[0],
	);

	const { hasUnfilledRequest = {}, makeRequest = {} } = useRequestsContext();

	const handleSubmit = async (e) => {
		e.preventDefault();
		const payload = Object();

		payload.name = nameRef.current.value;
		payload.short = shortRef.current.value;
		payload.curier_name = curierNameRef.current.value;
		payload.key = keyRef.current.value;
		payload.token = tokenRef.current.value;
		payload.tracking_link = trackingLinkRef.current.value;
		payload.scale = scaleRef.current.value;
		payload.provider = selectedProvider.value;

		const response = post
			? await makeRequest(updatePost.bind(null, post._id, payload))
			: await makeRequest(createPost.bind(null, payload));

		if (response?.data) {
			setShowPostForm(() => false);
			loadData();
		}
	};

	return (
		<>
			{hasUnfilledRequest(updatePost, createPost) && <Loading />}
			<PopUp setShow={setShowPostForm}>
				<Title>{post ? 'Edit courier' : 'Create courier'}</Title>
				<form onSubmit={(e) => handleSubmit(e)}>
					<Input
						name='Name'
						value={post?.name}
						inputRef={nameRef}
						required
						inputWidth='400'
						color={Colors.darkBlue}
					/>
					<Input
						inputRef={shortRef}
						value={post?.short}
						name='Short'
						required
						inputWidth='400'
						color={Colors.darkBlue}
					/>
					<Input
						inputRef={curierNameRef}
						value={post?.curier_name}
						name='Courier name'
						required
						inputWidth='400'
						color={Colors.darkBlue}
					/>
					<Input
						inputRef={trackingLinkRef}
						value={post?.tracking_link}
						name='Tracking link'
						required
						inputWidth='400'
						color={Colors.darkBlue}
					/>
					<Input
						inputRef={keyRef}
						value={post?.key}
						name='Key'
						required
						inputWidth='400'
						color={Colors.darkBlue}
					/>
					<Input
						inputRef={tokenRef}
						value={post?.token}
						name='Token'
						required
						inputWidth='400'
						color={Colors.darkBlue}
					/>
					<Input
						type='number'
						inputRef={scaleRef}
						value={post?.scale}
						name='Scale'
						required
						inputWidth='400'
						color={Colors.darkBlue}
					/>
					<SelectInput
						color={Colors.darkBlue}
						name={'Provider'}
						width={140}
						selectWidth={400}
						options={POST_PROVIDERS}
						selected={selectedProvider}
						setSelected={setSelectedProvider}
					/>
					<FLexRight>
						<button type='submit' className='btn btn-warning'>
							Save
						</button>
					</FLexRight>
				</form>
			</PopUp>
		</>
	);
};
