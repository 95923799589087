import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import EditIcon from '../common/components/EditIcon';
import Loading from '../common/components/Loading';
import withRequestProvider from '../common/hocs/withRequestProvider';
import { useRequestsContext } from '../common/hooks/requestHook';
import Table from '../common/components/Table';
import { getCurrencies } from '../API/repositories/currency';
import { CurrencyForm } from '../components/currencies/CurrencyForm';

const Wrapper = styled.div`
	padding: 10px;
`;

const Flex = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	margin-top: 40px;
`;

const Currencies = () => {
	const [editCurrencyData, setEditCurrencyData] = useState();
	const [showEditCurrency, setShowEditCurrency] = useState();
	const [currencies, setCurrencies] = useState();
	const [showCreateCurrency, setShowCreateCurrency] = useState();
	const navigate = useNavigate();

	const { hasUnfilledRequest, makeRequest } = useRequestsContext();

	const loadData = async () => {
		const response = await makeRequest(getCurrencies.bind());
		if (response?.data) {
			setCurrencies(() => response.data);
		}
	};

	const handleEditCurrency = (e, currency) => {
		e.preventDefault();

		setEditCurrencyData(() => currency);
		setShowEditCurrency(() => true);
	};

	useEffect(() => {
		loadData();
	}, []);

	const headers = ['No', 'Name', 'Short', 'Created at', 'Options'];
	const raws = useMemo(
		() =>
			currencies?.map((currency, i) => (
				<tr key={currency._id}>
					<td>{i + 1}.</td>
					<td>{currency.name}</td>
					<td>{currency.short}</td>
					<td>{moment(currency.created_at).format('YYYY-MM-DD HH:mm')}</td>
					<td>
						<EditIcon handleEdit={(e) => handleEditCurrency(e, currency)} />
					</td>
				</tr>
			)),
		[currencies],
	);

	return (
		<Wrapper>
			{hasUnfilledRequest(getCurrencies) && <Loading />}
			<Flex>
				<button
					className='btn btn-warning'
					onClick={() => navigate('/settings')}
				>
					Go back
				</button>
				<button
					className='btn btn-warning'
					onClick={() => setShowCreateCurrency(true)}
				>
					Create Currency
				</button>
			</Flex>
			{showCreateCurrency && (
				<CurrencyForm
					loadData={loadData}
					setShowCurrencyForm={setShowCreateCurrency}
				/>
			)}
			{showEditCurrency && (
				<CurrencyForm
					loadData={loadData}
					setShowCurrencyForm={setShowEditCurrency}
					currency={editCurrencyData}
				/>
			)}
			<Flex>
				{currencies && (
					<Table className='styled-table' raws={raws} headers={headers} />
				)}
			</Flex>
		</Wrapper>
	);
};

export default withRequestProvider(Currencies);
