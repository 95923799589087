import { FIELDS } from '../constants/fields';
import { SORTING_TYPES } from '../constants/sortingTypes';
import { HeaderContentWrapper, Icon, IconsContainer } from '../Orders.styled';
import { handleSort } from './handleSort';

export const getHeaders = ({ sortings, setSortings, setData, data }) => [
	<HeaderContentWrapper
		onClick={() => {
			const nextValue =
				sortings[FIELDS.COUNTRY.to] === 0
					? 1
					: sortings[FIELDS.COUNTRY.to] === 1
						? -1
						: 1;

			setSortings({
				...sortings,
				[FIELDS.COUNTRY.to]: nextValue,
			});
			setData(
				handleSort({
					field: FIELDS.COUNTRY.to,
					value: nextValue,
					ordersData: data,
					sortingType: SORTING_TYPES.STRING,
				}),
			);
		}}
	>
		Country
		<IconsContainer>
			<Icon
				display={sortings[FIELDS.COUNTRY.to] === 1}
				className='fa fa-sort-up'
			/>
			<Icon
				display={sortings[FIELDS.COUNTRY.to] === -1}
				className='fa fa-sort-down'
			/>
		</IconsContainer>
	</HeaderContentWrapper>,
	<HeaderContentWrapper
		onClick={() => {
			const nextValue =
				sortings[FIELDS.CITY.to] === 0
					? 1
					: sortings[FIELDS.CITY.to] === 1
						? -1
						: 1;

			setSortings({
				...sortings,
				[FIELDS.CITY.to]: nextValue,
			});
			setData(
				handleSort({
					field: FIELDS.CITY.to,
					value: nextValue,
					ordersData: data,
					sortingType: SORTING_TYPES.STRING,
				}),
			);
		}}
	>
		City
		<IconsContainer>
			<Icon
				display={sortings[FIELDS.CITY.to] === 1}
				className='fa fa-sort-up'
			/>
			<Icon
				display={sortings[FIELDS.CITY.to] === -1}
				className='fa fa-sort-down'
			/>
		</IconsContainer>
	</HeaderContentWrapper>,
	<HeaderContentWrapper
		onClick={() => {
			const nextValue =
				sortings[FIELDS.POSTAL_CODE.to] === 0
					? 1
					: sortings[FIELDS.POSTAL_CODE.to] === 1
						? -1
						: 1;

			setSortings({
				...sortings,
				[FIELDS.POSTAL_CODE.to]: nextValue,
			});
			setData(
				handleSort({
					field: FIELDS.POSTAL_CODE.to,
					value: nextValue,
					ordersData: data,
					sortingType: SORTING_TYPES.NUMBER,
				}),
			);
		}}
	>
		Postal code
		<IconsContainer>
			<Icon
				display={sortings[FIELDS.POSTAL_CODE.to] === 1}
				className='fa fa-sort-up'
			/>
			<Icon
				display={sortings[FIELDS.POSTAL_CODE.to] === -1}
				className='fa fa-sort-down'
			/>
		</IconsContainer>
	</HeaderContentWrapper>,
	<HeaderContentWrapper
		onClick={() => {
			const nextValue =
				sortings[FIELDS.STREET.to] === 0
					? 1
					: sortings[FIELDS.STREET.to] === 1
						? -1
						: 1;

			setSortings({
				...sortings,
				[FIELDS.STREET.to]: nextValue,
			});
			setData(
				handleSort({
					field: FIELDS.STREET.to,
					value: nextValue,
					ordersData: data,
					sortingType: SORTING_TYPES.STRING,
				}),
			);
		}}
	>
		Street
		<IconsContainer>
			<Icon
				display={sortings[FIELDS.STREET.to] === 1}
				className='fa fa-sort-up'
			/>
			<Icon
				display={sortings[FIELDS.STREET.to] === -1}
				className='fa fa-sort-down'
			/>
		</IconsContainer>
	</HeaderContentWrapper>,
	<HeaderContentWrapper
		onClick={() => {
			const nextValue =
				sortings[FIELDS.FULL_NAME.to] === 0
					? 1
					: sortings[FIELDS.FULL_NAME.to] === 1
						? -1
						: 1;

			setSortings({
				...sortings,
				[FIELDS.FULL_NAME.to]: nextValue,
			});
			setData(
				handleSort({
					field: FIELDS.FULL_NAME.to,
					value: nextValue,
					ordersData: data,
					sortingType: SORTING_TYPES.STRING,
				}),
			);
		}}
	>
		Full name
		<IconsContainer>
			<Icon
				display={sortings[FIELDS.FULL_NAME.to] === 1}
				className='fa fa-sort-up'
			/>
			<Icon
				display={sortings[FIELDS.FULL_NAME.to] === -1}
				className='fa fa-sort-down'
			/>
		</IconsContainer>
	</HeaderContentWrapper>,
	<HeaderContentWrapper
		onClick={() => {
			const nextValue =
				sortings[FIELDS.PHONE.to] === 0
					? 1
					: sortings[FIELDS.PHONE.to] === 1
						? -1
						: 1;

			setSortings({
				...sortings,
				[FIELDS.PHONE.to]: nextValue,
			});
			setData(
				handleSort({
					field: FIELDS.PHONE.to,
					value: nextValue,
					ordersData: data,
					sortingType: SORTING_TYPES.NUMBER,
				}),
			);
		}}
	>
		Phone
		<IconsContainer>
			<Icon
				display={sortings[FIELDS.PHONE.to] === 1}
				className='fa fa-sort-up'
			/>
			<Icon
				display={sortings[FIELDS.PHONE.to] === -1}
				className='fa fa-sort-down'
			/>
		</IconsContainer>
	</HeaderContentWrapper>,
	<HeaderContentWrapper
		onClick={() => {
			const nextValue =
				sortings[FIELDS.EMAIL.to] === 0
					? 1
					: sortings[FIELDS.EMAIL.to] === 1
						? -1
						: 1;

			setSortings({
				...sortings,
				[FIELDS.EMAIL.to]: nextValue,
			});
			setData(
				handleSort({
					field: FIELDS.EMAIL.to,
					value: nextValue,
					ordersData: data,
					sortingType: SORTING_TYPES.STRING,
				}),
			);
		}}
	>
		Email
		<IconsContainer>
			<Icon
				display={sortings[FIELDS.EMAIL.to] === 1}
				className='fa fa-sort-up'
			/>
			<Icon
				display={sortings[FIELDS.EMAIL.to] === -1}
				className='fa fa-sort-down'
			/>
		</IconsContainer>
	</HeaderContentWrapper>,
	<HeaderContentWrapper
		onClick={() => {
			const nextValue =
				sortings[FIELDS.DELIVERY_DATE.to] === 0
					? 1
					: sortings[FIELDS.DELIVERY_DATE.to] === 1
						? -1
						: 1;

			setSortings({
				...sortings,
				[FIELDS.DELIVERY_DATE.to]: nextValue,
			});
			setData(
				handleSort({
					field: FIELDS.DELIVERY_DATE.to,
					value: nextValue,
					ordersData: data,
					sortingType: SORTING_TYPES.DATE,
				}),
			);
		}}
	>
		Delivery date
		<IconsContainer>
			<Icon
				display={sortings[FIELDS.DELIVERY_DATE.to] === 1}
				className='fa fa-sort-up'
			/>
			<Icon
				display={sortings[FIELDS.DELIVERY_DATE.to] === -1}
				className='fa fa-sort-down'
			/>
		</IconsContainer>
	</HeaderContentWrapper>,
	<HeaderContentWrapper
		onClick={() => {
			const nextValue =
				sortings[FIELDS.PRICE.to] === 0
					? 1
					: sortings[FIELDS.PRICE.to] === 1
						? -1
						: 1;

			setSortings({
				...sortings,
				[FIELDS.PRICE.to]: nextValue,
			});
			setData(
				handleSort({
					field: FIELDS.PRICE.to,
					value: nextValue,
					ordersData: data,
					sortingType: SORTING_TYPES.NUMBER,
				}),
			);
		}}
	>
		Price
		<IconsContainer>
			<Icon
				display={sortings[FIELDS.PRICE.to] === 1}
				className='fa fa-sort-up'
			/>
			<Icon
				display={sortings[FIELDS.PRICE.to] === -1}
				className='fa fa-sort-down'
			/>
		</IconsContainer>
	</HeaderContentWrapper>,
	<HeaderContentWrapper
		onClick={() => {
			const nextValue =
				sortings[FIELDS.VARIANTS.to] === 0
					? 1
					: sortings[FIELDS.VARIANTS.to] === 1
						? -1
						: 1;

			setSortings({
				...sortings,
				[FIELDS.VARIANTS.to]: nextValue,
			});
			setData(
				handleSort({
					field: FIELDS.VARIANTS.to,
					value: nextValue,
					ordersData: data,
					sortingType: SORTING_TYPES.STRING,
				}),
			);
		}}
	>
		Variants
		<IconsContainer>
			<Icon
				display={sortings[FIELDS.VARIANTS.to] === 1}
				className='fa fa-sort-up'
			/>
			<Icon
				display={sortings[FIELDS.VARIANTS.to] === -1}
				className='fa fa-sort-down'
			/>
		</IconsContainer>
	</HeaderContentWrapper>,
	'Options',
];
