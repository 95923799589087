import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { getFlags } from '../API/repositories/flags';
import EditIcon from '../common/components/EditIcon';
import Loading from '../common/components/Loading';
import Table from '../common/components/Table';
import withRequestProvider from '../common/hocs/withRequestProvider';
import { useRequestsContext } from '../common/hooks/requestHook';
import { FlagForm } from '../components/flags/FlagForm';

const Wrapper = styled.div`
	padding: 10px;
`;

const Flex = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	margin-top: 40px;
`;

const ImageWrapper = styled.div`
	width: 100px;

	img {
		width: 100%;
		height: auto;
	}
`;

const Flags = () => {
	const [editFlagData, setEditFlagData] = useState();
	const [showEditFlag, setShowEditFlag] = useState();
	const [flags, setFlags] = useState();
	const [showCreateFlag, setShowCreateFlag] = useState();
	const navigate = useNavigate();

	const { hasUnfilledRequest, makeRequest } = useRequestsContext();

	const loadData = async () => {
		const response = await makeRequest(getFlags.bind());
		if (response?.data) {
			setFlags(() => response.data);
		}
	};

	const handleEditFlag = (e, flag) => {
		e.preventDefault();

		setEditFlagData(() => flag);
		setShowEditFlag(() => true);
	};

	useEffect(() => {
		loadData();
	}, []);

	const headers = ['No', 'Name', 'Short', 'Preview', 'Created at', 'Options'];
	const raws = useMemo(
		() =>
			flags?.map((flag, i) => (
				<tr key={flag._id}>
					<td>{i + 1}.</td>
					<td>{flag.name}</td>
					<td>{flag.short}</td>
					<td>
						<ImageWrapper>
							<img alt='flag' src={flag.base64} />
						</ImageWrapper>
					</td>
					<td>{moment(flag.created_at).format('YYYY-MM-DD HH:mm')}</td>
					<td>
						<EditIcon handleEdit={(e) => handleEditFlag(e, flag)} />
					</td>
				</tr>
			)),
		[flags],
	);

	return (
		<Wrapper>
			{hasUnfilledRequest(getFlags) && <Loading />}
			<Flex>
				<button
					className='btn btn-warning'
					onClick={() => navigate('/settings')}
				>
					Go back
				</button>
				<button
					className='btn btn-warning'
					onClick={() => setShowCreateFlag(true)}
				>
					Create Flag
				</button>
			</Flex>
			{showCreateFlag && (
				<FlagForm loadData={loadData} setShowFlagForm={setShowCreateFlag} />
			)}
			{showEditFlag && (
				<FlagForm
					loadData={loadData}
					setShowFlagForm={setShowEditFlag}
					flag={editFlagData}
				/>
			)}
			<Flex>
				{flags && (
					<Table className='styled-table' raws={raws} headers={headers} />
				)}
			</Flex>
		</Wrapper>
	);
};

export default withRequestProvider(Flags);
